<template>
  <div class="user">
    <div class="tit">{{ $t('reg_mainLandUser_1') }}</div>
    <div class="user-box">
      <el-form
        :model="ruleForm"
        :hide-required-asterisk="true"
        size="small"
        ref="ruleForm"
        label-width="100px"
        label-position="left"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('reg_mainLandUser_2')"
          :rules="[
            {
              required: true,
              message: $t('reg_mainLandUser_3'),
              trigger: 'blur',
            },
          ]"
          prop="username"
        >
          <el-input
            v-model="ruleForm.username"
            :placeholder="$t('reg_mainLandUser_4')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reg_mainLandUser_5')"
          :rules="[
            {
              required: true,
              message: $t('reg_mainLandUser_6'),
              trigger: 'blur',
            },
          ]"
          prop="surname"
        >
          <el-input
            v-model="ruleForm.surname"
            :placeholder="$t('reg_mainLandUser_7')"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('reg_mainLandUser_8')"
          :rules="[
            {
              required: true,
              message: $t('reg_mainLandUser_9'),
              trigger: 'blur',
            },
            {
              type: 'number',
              trigger: 'blur',
              message: $t('手机号格式不正确'),
            },
          ]"
          prop="mobile"
        >
          <el-input
            v-model.number="ruleForm.mobile"
            :placeholder="$t('reg_mainLandUser_10')"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="textCenter">
      <el-button :loading="nextLoading" @click="next" type="primary">
        {{ $t('reg_mainLandUser_11') }}
      </el-button>
      <el-button @click="back" type="text">{{
        $t('reg_mainLandUser_12')
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Email',
  data() {
    return {
      ruleForm: {
        email: localStorage.getItem('sm_email'),
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
      },
      isAgreement: false,
      nextLoading: false,
    }
  },
  created() {
    if (localStorage.getItem('sm_userinfo')) {
      this.ruleForm = {
        ...JSON.parse(localStorage.getItem('sm_userinfo')),
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
      }
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'Identity' })
    },
    next() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.nextLoading = true
          let res = await this.$axios.post('/api/User/register', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.$router.push({ name: 'IdentityVerification' })
            localStorage.setItem(
              'sm_userinfo',
              JSON.stringify(res.data.userinfo)
            )
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  width: 400px;
  margin: 80px auto 0;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 40px;
    text-align: center;
  }
}
.textCenter {
  margin-top: 50px;
  .el-button {
    width: 100%;
    margin-left: 0;
  }
}
</style>
