var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('reg_mainLandUser_1')))]),_c('div',{staticClass:"user-box"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"hide-required-asterisk":true,"size":"small","label-width":"100px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":_vm.$t('reg_mainLandUser_2'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_mainLandUser_3'),
            trigger: 'blur',
          } ],"prop":"username"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_mainLandUser_4')},model:{value:(_vm.ruleForm.username),callback:function ($$v) {_vm.$set(_vm.ruleForm, "username", $$v)},expression:"ruleForm.username"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('reg_mainLandUser_5'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_mainLandUser_6'),
            trigger: 'blur',
          } ],"prop":"surname"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_mainLandUser_7')},model:{value:(_vm.ruleForm.surname),callback:function ($$v) {_vm.$set(_vm.ruleForm, "surname", $$v)},expression:"ruleForm.surname"}})],1),_c('el-form-item',{attrs:{"label":_vm.$t('reg_mainLandUser_8'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_mainLandUser_9'),
            trigger: 'blur',
          },
          {
            type: 'number',
            trigger: 'blur',
            message: _vm.$t('手机号格式不正确'),
          } ],"prop":"mobile"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_mainLandUser_10')},model:{value:(_vm.ruleForm.mobile),callback:function ($$v) {_vm.$set(_vm.ruleForm, "mobile", _vm._n($$v))},expression:"ruleForm.mobile"}})],1)],1)],1),_c('div',{staticClass:"textCenter"},[_c('el-button',{attrs:{"loading":_vm.nextLoading,"type":"primary"},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t('reg_mainLandUser_11'))+" ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t('reg_mainLandUser_12')))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }